import { navigate as reachNavigate } from '@reach/router';
import { captureException } from '@sentry/browser';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import React from 'react';

import { Layout, LayoutBrandData } from '../components/layout';
import { Spinner } from '../components/spinner';
import * as analytics from '../services/analytics';
import { authenticate } from '../services/kantan-client';

type AuthState = {
  isAuthenticating: boolean;
  error: string | null;
};

type AuthAction =
  | {
      type: 'success' | 'authenticating';
    }
  | {
      type: 'error';
      error: string | null;
    };

const authStateReducer: React.Reducer<AuthState, AuthAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'authenticating':
      return { isAuthenticating: true, error: null };
    case 'success':
      return { isAuthenticating: false, error: null };
    case 'error':
      return {
        isAuthenticating: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const urlWithUtmInfo = (url: string): string => {
  // Attaches the UTM information from this pages querystring to the
  // redirect url passed. Allows this to be sent up with the
  // 'Portal-Booking page viewed' event in analytics.js.
  const q = new URLSearchParams(window.location.search);
  const campaign = `?utm_campaign=${q.get('utm_campaign')}`;
  const source = `&utm_source=${q.get('utm_source')}`;
  const medium = `&utm_medium=${q.get('utm_medium')}`;
  const queryString = campaign + source + medium;
  return url + queryString;
};

const useAuthenticateAndRedirect = (token: string, redirectTo: string) => {
  const [state, dispatch] = React.useReducer(authStateReducer, {
    isAuthenticating: true,
    error: null,
  });

  React.useEffect(() => {
    authenticate(token)
      .then(() => {
        dispatch({ type: 'success' });
        reachNavigate(urlWithUtmInfo(redirectTo), { replace: true });
        analytics.identifyUser(token);
      })
      .catch((e) => {
        captureException(e);
        dispatch({ type: 'error', error: 'Something went wrong.' });
      });
  }, [redirectTo, token]);

  return [state.isAuthenticating, state.error];
};

type BrandNode = LayoutBrandData;

type QueryData = {
  brand: BrandNode;
};

type AuthenticatePageProps = { data: QueryData; location: Location };

const AuthenticatePage: React.FC<AuthenticatePageProps> = ({
  data,
  location,
}) => {
  // Extract search parameters
  const parsedQuery = queryString.parse(location.search);
  const queryToken = parsedQuery['token'];
  const queryRedirectTo = parsedQuery['redirectTo'];

  const token = queryToken && typeof queryToken === 'string' ? queryToken : '';
  const redirectTo =
    queryRedirectTo && typeof queryRedirectTo === 'string'
      ? queryRedirectTo
      : '';

  const [isAuthenticating, error] = useAuthenticateAndRedirect(
    token,
    redirectTo,
  );

  const statusMsg = isAuthenticating ? (
    <Spinner />
  ) : error ? (
    error
  ) : (
    <Spinner />
  );

  return (
    <Layout brand={data.brand}>
      <section className="section message">
        <div className="container--small center">
          <h1 className="heading2">{statusMsg}</h1>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query AuthenticatePageBrandInfo($brandId: String) {
    brand: brandsJson(id: { eq: $brandId }) {
      ...LayoutBrandFragment
    }
  }
`;

export default AuthenticatePage;
